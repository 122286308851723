import React, { useState, useEffect } from "react";
import supabase from "./supabaseClient";
import "./SalahTimes.css";
import MonthlyPrayerTimetable from "./MonthlyPrayerTimetable.js"; // Import the new component

const formatDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

const SalahTimes = () => {
  const [salahTimes, setSalahTimes] = useState(null);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // To handle popup state

  // Get today's date in YYYY-MM-DD format
  const getTodayDateInTimeZone = () => {
    const date = new Date();
    const offsetMinutes = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offsetMinutes * 60000);
    return adjustedDate.toISOString().split("T")[0];
  };

  const todayDate = getTodayDateInTimeZone();
 

  // Fetch Salah times for today
  useEffect(() => {
    const fetchSalahTimes = async () => {
      const { data, error } = await supabase
        .from('salah_times')
        .select('*')
        .eq('d_date', todayDate)        
        
        

      if (error) {
        setError("Could not fetch Salah times. Please ensure the date format is correct.");
      } else {
        data.sort((a, b) => a.id - b.id);
        setSalahTimes(data[0]);
        console.log(data)
      }
    };

    fetchSalahTimes();
  }, [todayDate]);

  // Function to format time
  const formatTime = (timeString) => {
    return timeString ? timeString.slice(0, 5) : "-"; // Only keep 'HH:mm'
  };

  return (
    <div id="salah-times" className="section-components-1">
      <h1 className="salah-times-title">Prayer Times</h1>
      <div className="salah-times-container">
        <div className="date-cell">
          <th colSpan="3">{formatDate(new Date())}</th>
        </div>

        {error && <p className="error-message">{error}</p>}

        {salahTimes ? (
          <table className="salah-times-table">
            <thead>
              <tr>
                <th>Prayer</th>
                <th>Begins</th>
                <th>Jama'ah</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fajr</td>
                <td>{formatTime(salahTimes.fajr_begins)}</td>
                <td>{formatTime(salahTimes.fajr_jamah)}</td>
              </tr>
              <tr>
                <td>Sunrise</td>
                <td>{formatTime(salahTimes.sunrise)}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Zuhr</td>
                <td>{formatTime(salahTimes.zuhr_begins)}</td>
                <td>{formatTime(salahTimes.zuhr_jamah)}</td>
              </tr>
              <tr>
                <td>Asr</td>
                <td>{formatTime(salahTimes.asr_mithl_1)}</td>
                <td>{formatTime(salahTimes.asr_jamah)}</td>
              </tr>
              <tr>
                <td>Maghrib</td>
                <td>{formatTime(salahTimes.maghrib_begins)}</td>
                <td>{formatTime(salahTimes.maghrib_jamah)}</td>
              </tr>
              <tr>
                <td>Isha</td>
                <td>{formatTime(salahTimes.isha_begins)}</td>
                <td>{formatTime(salahTimes.isha_jamah)}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <br></br><p className="jummah">JUMMAH PRAYER STARTS AT 12:45PM</p>

      {/* Button to show monthly prayer timetable */}
      <button className="btn-salah" onClick={() => setShowPopup(true)}>
        Monthly Prayer Timetable
      </button>

      {/* Monthly Prayer Timetable Popup */}
      {showPopup && (
        <MonthlyPrayerTimetable
          todayDate={todayDate}
          onClose={() => setShowPopup(false)} // Handle close event
        />
      )}
    </div>
  );
};

export default SalahTimes;