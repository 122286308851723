import React, { useState, useEffect } from "react";
import supabase from "./supabaseClient";
import "./MonthlyPrayerTimetable.css";

const MonthlyPrayerTimetable = ({ todayDate, onClose }) => {
  const [monthlySalahTimes, setMonthlySalahTimes] = useState(null);
  const [error, setError] = useState(null);

  // Get the current month (YYYY-MM)
  const currentMonth = todayDate.substring(0, 7);

  // Function to get the full name of the month (e.g., "March")
  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long" }; // Full month name
    return date.toLocaleDateString("en-US", options);
  };

  // Get the full month name for the title
  const monthName = getMonthName(todayDate);

  const currentDate = new Date().toISOString().split("T")[0];

  // Fetch monthly Salah times
  useEffect(() => {
    const fetchMonthlySalahTimes = async () => {
      const { data, error } = await supabase
        .from('salah_times')
        .select('*')
        .gt('d_date', currentDate);

      if (error) {
        setError("Could not fetch monthly Salah times.");
      } else {
        setMonthlySalahTimes(data);
      }
    };

    fetchMonthlySalahTimes();
  }, [currentMonth]);

  // Function to format time
  const formatTime = (timeString) => {
    return timeString ? timeString.slice(0, 5) : "-"; // Only keep 'HH:mm'
  };

  // Function to get the date suffix (st, nd, rd, th)
  const getDateSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // Handling numbers 11-20
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Function to format the date as "Mon 1st", "Tues 2nd", etc.
  const formatMonthlyDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: "short" }; // "Mon", "Tue", etc.
    const day = date.getDate();
    const daySuffix = getDateSuffix(day);

    // Return formatted date like "Mon 1st"
    return `${date.toLocaleDateString("en-US", options)} ${day}${daySuffix}`;
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>{monthName}</h2>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        {error && <p className="error-message">{error}</p>}
        {monthlySalahTimes ? (
          <table className="monthly-salah-times-table">
            <thead>
              <tr>
                <th rowSpan="2">Date</th>
                {/* Split columns for each Salah */}
                <th colSpan="2">Fajr</th>
                <th rowSpan="2">Sunrise</th> {/* No Jama'ah time for Sunrise */}
                <th colSpan="2">Zuhr</th>
                <th colSpan="2">Asr</th>
                <th colSpan="2">Maghrib</th>
                <th colSpan="2">Isha</th>
              </tr>
              <tr>
                {/* Sub-columns for each Salah */}
                <th>Begins</th>
                <th>Jama'ah</th>
                <th>Begins</th>
                <th>Jama'ah</th>
                <th>Begins</th>
                <th>Jama'ah</th>
                <th>Begins</th>
                <th>Jama'ah</th>
                <th>Begins</th>
                <th>Jama'ah</th>
              </tr>
            </thead>
            <tbody>
              {monthlySalahTimes.map((day, index) => (
                <tr
                  className={day.d_date === currentDate ? "highlight-row" : ""}
                  key={index}
                >
                  <td>{formatMonthlyDate(day.d_date)}</td>
                  {/* Fajr */}
                  <td>{formatTime(day.fajr_begins)}</td>
                  <td>{formatTime(day.fajr_jamah)}</td>
                  {/* Sunrise */}
                  <td>{formatTime(day.sunrise)}</td>
                  {/* Zuhr */}
                  <td>{formatTime(day.zuhr_begins)}</td>
                  <td>{formatTime(day.zuhr_jamah)}</td>
                  {/* Asr */}
                  <td>{formatTime(day.asr_mithl_1)}</td>
                  <td>{formatTime(day.asr_jamah)}</td>
                  {/* Maghrib */}
                  <td>{formatTime(day.maghrib_begins)}</td>
                  <td>{formatTime(day.maghrib_jamah)}</td>
                  {/* Isha */}
                  <td>{formatTime(day.isha_begins)}</td>
                  <td>{formatTime(day.isha_jamah)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Loading monthly Salah times...</p>
        )}
      </div>
    </div>
  );
};

export default MonthlyPrayerTimetable;